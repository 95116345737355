<template>

    <div class="section collab-section">
      <div class="left">
        <div class="voile"></div>
        <div class="content">
          <img src="../../../../public/assets/images/logo11.png" class="logo1" /><br />
          
        </div>
      </div>
      <div class="right">
        <div class="voile"></div>
        <div class="content">
          <img src="../../../../public/assets/images/logo22.png" class="logo1" /><br />
          
        </div>
      </div>
    </div>

</template>

<script>

export default {
    name: "HomeCollaboration",
    data(){
      
        return{
         
        }
    },
    mounted()
    {
      
    },
    beforeUnmount() {
       
    },
    destroyed() {
      
    },
    methods:{
     
    }
}
</script>

<style scoped>
 
 @media screen and (max-width:760px)
 {
  .content .x
  {
    width:35px !important;
  }
  .content .logo1 { 
    width:180px !important;
  }
  .content .logo2 { 
    width:140px !important;
  }
  .section.collab-section .left,.section.collab-section .right{
    width:100%;
    min-height:50vh;
  }
  .section.collab-section 
  {
    margin-top:100px;
    flex-direction: column;
  }
 }
.section.collab-section {
  padding:0;
  display:flex;
  
} 
.right,.left
{
  min-height: 100vh;
}
.left:hover .voile,
.left:hover .content,
.right:hover .voile,
.right:hover .content {
opacity:1
}
.content .logo2 {
  margin-top:20px;
  width:250px;
  display:inline-block;
}
.content .logo1 {
  width:300px;
  margin-bottom:20px;
  display:inline-block;
}
.content .x {
  width:55px;
  display:inline-block;
}
.content {
  opacity:0;
  transition:all 0.3s;
  position:relative;
  z-index:3;
  text-align: center;
}
.voile {
  background: rgba(0,0,0,0.51);
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  opacity:0;
  transition:all 0.3s;
}
.right {
  position:relative;
  display:flex;
  height:100%;
  align-items: center;
  justify-content: center;
  width:50%;
  background-position: center top;
  background-image:url(../../../../public/assets/images/collabdroite.jpg);
  background-size:cover;
}
.left {
  position:relative;
  display:flex;
  height:100%;
  align-items: center;
  justify-content: center;
  width:50%;
  background-position: center center;
  background-image:url(../../../../public/assets/images/collabgauche.jpg);
  background-size:cover;
}

</style>