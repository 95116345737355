export default {
    data:[
        {
            img:'video1.png',
            video:'video.mp4',
            title:'EPISODE 01',
            duration:'1:30 min',
            description:'EYEWEAR DESIGN. PROCESS'
            
        },
        {
            img:'video1.png',
            video:'video.mp4',
            title:'EPISODE 02',
            duration:'2:44 min',
            description:'PRODUCTION - 3D PRINTING'
            
        },
        {
            img:'video1.png',
            video:'video.mp4',
            title:'EPISODE 03',
            duration:'1:58 min',
            description:'QUALITY CONTROL & ASSEMBLING'
            
        }
    ]
}