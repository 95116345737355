<template>
  
 <a :href="link" class="seemore" :class="{'gradient':color=='gradient', 'black':color=='black'}"><span class="text">{{text}}</span> <span class="arrow"></span></a>
    
</template>

<script>


export default {
  name: 'SeeMore',
  props:['link','text',"color"],
  data() {
    return {

    }
  },
  watch:{
    
  },
  async mounted(){
   
  },
  methods:{
   
  },

}
</script>
<style scoped>

.seemore.black .arrow:after,.seemore.black .arrow:before
{   
background-image: url(../../../public/assets/images/2021/04/arrow2.svg);
  
}

.seemore.black .arrow
{
	
    border: 1px solid #fff;
    color: #fff;
    top:25px;
    right:18px;
}

.arrow:before
{
	background-image: url(../../../public/assets/images/2021/04/arrow.svg);
    content: ' ';
    position: absolute;
    width: 20px;
    transition: all .3s cubic-bezier(.55,.055,.675,.19);
    transform: translate3d(-100%,0,0);
    opacity: 0;
    background-size: 100% 100%;
    height: 20px;
    right: 4px;
    top: 4px;
    display: block;
}
.seemore.gradient .text {
    border-bottom:0;
}
.seemore .text {
    border-bottom:1px solid black;
    width: 100%;
}
.seemore {
    color:#000;
    padding-right: 48px;
    position: absolute;
    font-weight: 700;
}
.arrow
{
	cursor: pointer;
    border: 0;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 10px;
    padding: 0;
    top: 12px;
    transform: translateY(-50%);
    background-color: transparent;
    background: transparent;
    border: 1px solid #000;
    color: #000;
    border-radius: 50%;
    text-decoration: none;
    opacity: 1;
}
.arrow:after
{   
background-image: url(../../../public/assets/images/2021/04/arrow.svg);
    content: ' ';
    position: absolute;
    width: 20px;
    background-size: 100% 100%;
    height: 20px;
    right: 4px;
    top: 4px;
    display: block;
    transition: all .7s cubic-bezier(.23,1,.32,1) .2s;
}

.arrow:hover::after
{
	
transition:all .3s cubic-bezier(.55,.055,.675,.19);
transform:translate3d(100%,0,0);
opacity:0;


	

}
.arrow:hover::before
{

transition: all .7s cubic-bezier(.23,1,.32,1) .2s;
transform: translateZ(0);
opacity: 1;
}
a {
    text-decoration: none;
}
.seemore.gradient .arrow {
    top:25px;
    right:18px;
}
.seemore.gradient {
    font-weight: normal;
border-radius: 48px;
padding-top:15px ;
padding-bottom:15px ;
padding-left:30px;
background: linear-gradient(to left, #CCDCF8, #DCD5F7 100%);
padding-right: 58px;


}

.seemore.black {
    font-weight: normal;
    border-radius: 48px;
    padding-top:15px ;
    padding-bottom:15px ;
    padding-left:30px;
    background: #000;
    padding-right: 58px;
    color:#fff;
    
    }
</style>