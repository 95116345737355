<template>
      <div class="video-popup">
          <div class="content">
            <img src="../../../../public/assets/home/cross.svg" class="cross" @click="hideVideoPopup" />
            <video autoplay loop playsinline muted id="videohome"  class="contenudesk">

              <source :src="require('../../../../public/assets/home/'+video.video)"
                  type="video/mp4">

              Sorry, your browser doesn't support embedded videos.
            </video>
          </div>
      </div>
</template>

<script>

export default {
    name: "HomeCreation",
    props: ['video','hideVideoPopup'],
    data(){
        return{
           
        }
    },
    mounted()
    {
    
    }

}
</script>

<style scoped>
.video-popup .content video {
  width:100%;
  height:auto;
}
.video-popup .content {
  background:#fff;
  width:80%;
  padding:30px;
  position: absolute;
  top:50%;
  left:50%;
  border-radius: 17px;
  transform: translate(-50%,-50%);
}
.video-popup
{
  position: fixed;
  z-index:9999;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background:rgba(0,0,0,0.7);
}
.cross {
  width:40px;
  position:absolute;
  right:-10px;
  top:-10px;
}


</style>