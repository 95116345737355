<template>

  <div class="home landing">

    <HomeTopComponent :language="language" />
    
    <HomeBlackComponent :scrolled="scrolled" :language="language"  />

    <HomeCollectionBetaComponent 
    v-if="scrolled"
    :displayOptic="true" :displayBluelight="displayBluelight" :displayGaming="displayGaming"
      :filterNumber="filterNumber"
      :barWidth="barWidth" 
      :barPosition="barPosition" 
      :glasses="glasses" 
      :photos="photos"
      :selectedFilter="selectedFilter"

      :language="language" 

      @filterCollectionFn="filterCollection"
   
    />

    <HomeCommunityComponent
    v-if="scrolled"
      :displayOptic="true" :displayBluelight="displayBluelight" :displayGaming="displayGaming"
      :filterNumber="filterNumber"
      :barWidth="barWidth" 
      :barPosition="barPosition" 
      :glasses="glasses" 
      :photos="photos"
      :selectedFilter="selectedFilter"
      :language="language" 

      @filterCollectionFn="filterCollection"
     />
    
    <!-- <HomeCollectionComponent 
    v-if="scrolled"
      :displayOptic="true" :displayBluelight="displayBluelight" :displayGaming="displayGaming"
      :filterNumber="filterNumber"
      :barWidth="barWidth" 
      :barPosition="barPosition" 
      :glasses="glasses" 
      :photos="photos"
      :selectedFilter="selectedFilter"
      :language="language" 

      @filterCollectionFn="filterCollection"
    /> -->
    
    <HomeCollaborationComponent
    
    :language="language" 
     />
    <LandingSustainabilityComponent

    v-if="scrolled"
    :language="language" 
     />
    <!-- <LandingCustomizationsComponent 
    :language="language" 
     /> -->

    <FaqCompotent :language="language"  />
    <!-- <LandingCustomizationsComponent />
    <HomeTutoDeskComponent />
    <LandingSpecificationComponent />
    <HomeCreationComponent :average="average" />
    
  
    <HomeProduceComponent />
    <HomeVisuComponent /> -->

  </div>
  <Footer :language="language" :displayModalMenu="displayModalMenu" />
 
</template>

<script>
import Footer from '../components/layout/Footer.vue'
import HomeBlackComponent from "../components/children/Landing/HomeBlackComponent.vue";
import FaqCompotent from "../components/children/Landing/HomeFaqComponentNew.vue";
import HomeTopComponent from '../components/children/Landing/HomeTopComponent2.vue';
import HomeWhiteComponent from '../components/children/Landing/HomeWhiteComponent.vue';
import HomeCollectionBetaComponent from '../components/children/Landing/HomeCollectionBetaComponent.vue';
import HomeTutoDeskComponent from '../components/children/Landing/HomeTutoDeskComponent.vue';
import HomeCollectionComponent from '../components/children/Landing/HomeCollectionComponent2.vue';
import HomeCommunityComponent from '../components/children/Landing/HomeCommunityComponent.vue';
import HomeCollaborationComponent from '../components/children/Landing/HomeCollaborationComponent2.vue';
import HomeCreationComponent from '../components/children/Landing/HomeCreationComponent.vue';
import LandingCustomizationsComponent from '../components/children/Landing/LandingCustomizationsComponent2.vue';
import LandingSpecificationComponent from '../components/children/Landing/LandingSpecificationComponent.vue';
import LandingSustainabilityComponent from '../components/children/Landing/LandingSustainabilityComponent.vue';
import HomeProduceComponent from '../components/children/Landing/HomeProduceComponent.vue';
import HomeVisuComponent from '../components/children/Landing/HomeVisuComponent.vue';
import { useMeta } from 'vue-meta'
import { useSettingsStore } from '@/store/SettingsStore.js';

export default {
  name: 'Landing',
  components: {
    Footer,
    HomeTopComponent,
    HomeCollectionBetaComponent,
    HomeCommunityComponent,
    HomeWhiteComponent,
    HomeBlackComponent,
    HomeTutoDeskComponent,
    FaqCompotent,
    HomeCollectionComponent,
    HomeCollaborationComponent,
    HomeCreationComponent,
    LandingCustomizationsComponent,
    LandingSpecificationComponent,
    LandingSustainabilityComponent,
    HomeProduceComponent,
    HomeVisuComponent
  },
  data() {
      return {
        useSettings:useSettingsStore(),
        customColor:0,
        scrolled:false,
        displayOptic:false,
        displayGaming:false,
        displayBluelight:false,
        customTexture:0,
        filterNumber:2,
        barWidth:50,
        currentTuto:0,
        activeCustom:'color',
        activeColor:0,
        average:4.5,
        loaded:false,
        barPosition:0,
        glasses:[],
        controller: null,
        tween: null,
        columns:1,
        scene: null,
        email:null,
        currentOption:'colors',
        showModal:false,
        currentImage:'',
        currentColor:'color0',
        showDevices:false,
        selectedFilter:'solar',
       
        underlinePosition:0,
        photos:[],
       
        }
    },
    props:["displayModalMenu","language"],
  methods:{

    handleScroll(){
            if(window.scrollY>500)
            {
              this.scrolled=true
                
            }
        },

   
    filterCollection(filter)
    {

      if(filter=='solar')
      {

          this.barWidth=50
          this.barPosition=0

          
          
      }
      else if(filter=="optic")
      {

       this.barWidth=50
       this.barPosition=50
          
      }
      else if(filter=="collaborations")
      {
          if(this.displayOptic==true) {
              this.barPosition=32
          }
          else {
            this.barPosition=16
          }
         this.barWidth=26
         
      }
      else if(filter=="bluelight")
      {

        this.barWidth=26
          
        if(this.displayOptic==true) {
            this.barPosition=58
        }
        else {
          this.barPosition=42
        }
      
  
      }
      else
      {

          this.barWidth=16
            

          if(this.displayBluelight==true && this.displayOptic==true) {
              this.barPosition=84
          }
          else if(this.displayOptic==true || this.displayBluelight==true) {
              this.barPosition=58
          }
          else {
              this.barPosition=32
          }
          
      }

        
        this.selectedFilter=filter
        
    },

  },
  created() {

    useMeta({
    title: "VISAGES - Perfect Fit, Limitless Design.",
    
    htmlAttrs: { lang: this.language, amp: true },
    
})


    if(this.language=='fr')
    {
      document.querySelector('meta[name="description"]').setAttribute('content','Nos lunettes sont imprimées en 3D, à la demande, en utilisant des bio-matériaux, avec une politique de fabrication zéro déchet.')
    }
    else
    {
      document.querySelector('meta[name="description"]').setAttribute('content','Our eyewear is 3d printed, on demand, using sustainable materials, with zero waste manufacturing policy.')
    }
    
    

  },
  async mounted()
  {
  
    if(this.useSettings.settingsLoaded==false) {
      await this.useSettings.loadSettings()
    }
    
      if(this.useSettings.displayOptic==true) {
            this.displayOptic=true;
        }
        if(this.useSettings.displayBluelight==true) {
            this.displayBluelight=true;
        }
        if(this.useSettings.displayGaming==true) {
            this.displayGaming=true;
        }

        if(this.$route.params.lang)
      {
        this.$emit('changeLanguageEv', this.$route.params.lang)
        
      }
   
    this.filterCollection('solar');

    if(this.displayOptic==true && this.displayGaming==true && this.displayBluelight==true)
    {
        this.filterNumber=5
    }
    else if((this.displayOptic==true && this.displayGaming==true) || (this.displayGaming==true && this.displayBluelight==true) || (this.displayOptic==true && this.displayBluelight==true))
    {
        this.filterNumber=4
    }
    else if(this.displayOptic==true || this.displayGaming==true || this.displayBluelight==true)
    {
        this.filterNumber=3
    }

    window.addEventListener('scroll', this.handleScroll);

    this.loaded=true

  }
  
}
</script>

<style scoped>
#cart-icon.landing {
  top:51px;
}
.home {
 
  }

  .rounded-white-top{
    z-index:2;
    border-radius: 67px;
    background:#fff;
    position:relative;
    margin-top:-50px;
  }
  
  .rounded-white-bottom{
    z-index:2;
    border-radius: 67px;
    background:#fff;
    position:relative;
    margin-bottom:-50px;
  }
  
.home.landing
{
  padding-top:104px;
  margin-top:-104px;
}

</style>



