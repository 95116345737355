<template>
      <div class="section home-top">
      
        <div class="content">
          <h1 class="title">
            Perfect Fit,<br />
            Limitless Design.
          </h1>

          <div class="button-white" v-if="language=='fr'" @click="$router.push({ name: 'collection-beta'})">Personnalisation</div>
          <div class="button-white" v-else @click="$router.push({ name: 'collection-beta' })">Customization</div>
          <!-- <div class="button-white arrow" @click="$router.push({ name: 'collection', params: { toggle: 'solaire' } })"><img src="../../../../public/assets/images/down.svg" height="24" /></div> -->
        </div>

        <div class="bandeau-logos">
          <div class="logos">
            <div class="txt slider-logo">
              <div class="logo">
                <img src="../../../../public/assets/images/Logo-Hytrape.svg" width="350" />
              </div>
              <div class="logo">
                <img src="../../../../public/assets/images/Logo-Lunettes-originales.svg"  width="350" />
              </div>
              <div class="logo">
                <img src="../../../../public/assets/images/Logo-Monsieur.svg"  width="350" />
              </div>
              <div class="logo">
                <img src="../../../../public/assets/images/Logo-Vogue.svg"  width="350" />
              </div>
              <div class="logo">
                <img src="../../../../public/assets/images/Logo-Hytrape.svg" width="350" />
              </div>
              <div class="logo">
                <img src="../../../../public/assets/images/Logo-Lunettes-originales.svg"  width="350" />
              </div>
              <div class="logo">
                <img src="../../../../public/assets/images/Logo-Monsieur.svg"  width="350" />
              </div>
              <div class="logo">
                <img src="../../../../public/assets/images/Logo-Vogue.svg"  width="350" />
              </div>
              <div class="logo">
                <img src="../../../../public/assets/images/Logo-Hytrape.svg" width="350" />
              </div>
              <div class="logo">
                <img src="../../../../public/assets/images/Logo-Lunettes-originales.svg"  width="350" />
              </div>
              <div class="logo">
                <img src="../../../../public/assets/images/Logo-Monsieur.svg"  width="350" />
              </div>
              <div class="logo">
                <img src="../../../../public/assets/images/Logo-Vogue.svg"  width="350" />
              </div>
            </div>
            
          </div>
        </div>
       
    </div>
</template>

<script>
import { tns } from "../../../../node_modules/tiny-slider/src/tiny-slider"

export default {
    name: 'HomeTopComponent2',
    props:["language"],
    data(){
        return {
          slider_logo:null,
          collab1SliderOptions: {
               container: '.slider-logo',
               mouseDrag: false,
               navigation:false,
               navPosition:'bottom',
               autoplay:true,
               loop: true,
               slideBy:5,
               items: 3,
               center:false,
               gutter: 0,
               swipeAngle: 45,
               nav:false,
               controls:false,
             
               startIndex:0,
               responsive: {
                1024: {
                       items: 5,
                       gutter:0
               },

                
               760: {
                       items: 4,
                       gutter:0
               }
               
               },
           },
        }
    },
    methods: {
        
    },
    mounted() {
      this.slider_logo = tns(
          this.collab1SliderOptions
      );
    }

}
</script>

<style scoped>

@media screen and (max-width:760px) {

  .bandeau-logos
  {
    padding-top:30px !important;
    padding-bottom:30px !important;
  }
 
  .button-white 
  {
    font-size:15px !important;
  }
}
.bandeau-logos .logos {
  display:flex;
  overflow: hidden;
  align-items: center;
}
.txt {
  display:flex;
  white-space: nowrap;
  /* animation:Scroll 10s linear infinite; */
}
@keyframes Scroll {
  0% {
    transform: translateX(-100%);
  }
}
.bandeau-logos .logo img 
{
  width:50%;
  margin-left: auto;
  position:relative;
  top:50%;
  transform: translateY(-50%);
  margin-right:auto;
}
.bandeau-logos .logo {
  width:400px;
  display:flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.bandeau-logos {
 

  background: rgba(255, 255, 255, 0.1) 0% 0% no-repeat padding-box;
  opacity: 1;
  backdrop-filter: blur(24px);
  -webkit-backdrop-filter: blur(24px);
  padding-top:60px;
  padding-bottom:60px;
  position:absolute;
  bottom:0;
  left:0;
  width:100%;
}

  .home-top.section {
    min-height:calc(100vh + 200px);
    display:flex;
    position:relative;
  }
  .home-top.section .content h1 .strong
  {
    font-weight: 600;
    font-style:italic;
  }
  .home-top.section .content h1 {
    font-family: 'degular';
    color:#fff;
    font-size:60px;
    line-height:62px;
    text-align: left;
    font-weight: normal;
    word-break: keep-all;
  }
  .home-top.section .content {
    position:absolute;
    left:5%;
    bottom:350px;
    text-align: left;
   
  }
  .home-top.section {
    
    background-image:url(../../../../public/assets/home/home.jpg);
    background-size:cover;
    background-position: center center;
  }
  .home-top.section .right {
    width:40%;
    background:#000;
    display:flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  

  .button-black {
    color:#fff;
    margin-top:10px;
    background:#000;
    display:inline-block;
    padding:5px 15px;
    font-size:12px;
    border-radius:40px;
  }
  .button-black span {
    position:relative;
    top:-5px;
    margin-left:10px;
  }
  .button-black img {
    display:inline-block;
  }
  .button-white.arrow 
  {
    margin-left:10px;
    width:51px;
    height:51px;
    display:inline-block;
    padding:0;
    vertical-align: top;
  }
  .button-white.arrow img {
    height:22px;
    position:relative;
    filter: brightness(0) invert(1);
    top:13px;
    transform: rotate(-90deg);
  }
  .button-white img {
    display:inline-block;
  }
  .button-white:hover {
  
  background: transparent linear-gradient(90deg, #000000 0%, #0000E0E6 100%) 0% 0% no-repeat padding-box;
  border: 0.5px solid #0000E0;

  }
  
  .button-white {
    color:#fff;

    border: 0.5px solid transparent;
    transition: all 0.3s;
    cursor: pointer;
    font-size: 23px;
    line-height: 23px;
   
    font-weight: normal;
    background: rgba(255,255,255,0.1);
    opacity: 1;
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);

    border-radius: 10px;

    padding-top: 25px;
    font-weight: 600;
    padding-bottom: 26px;
    padding-left: 80px;

    padding-right: 80px;
    text-align: center;
    display: inline-block;
  
  }
  .button-white span {

    position:relative;
    top:-5px;
    margin-left:10px;
  }


</style>