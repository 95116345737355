export default {
    data:[
        {
            img:'creation.png',
            title:'LUNETTE CHAÏ',
            name:'Par Jeanne.B'
            
        },
        {
            img:'creation.png',
            title:'LUNETTE CHAÏ',
            name:'Par Marc.Z'
        },
        {
            img:'creation.png',
            title:'LUNETTE CHAÏ',
            name:'Par Leo.R'
        }
    ]
}