<template>
      <div class="section section-white">
      <div class="top">
        <div class="slider-magazine" v-for="(i, index) in loop_slider" :key="index" >
            <div class="slider-container">
                <div class="logo">MAGAZINE</div>
            </div>
        </div>
      </div>
    
    </div>
</template>

<script>

import SeeMore from '../../elements/SeeMore.vue';

export default {
    name: "HomeWhiteComponent",
    components: {
        SeeMore
    },
    data(){
        return{
            loop_slider: 5,
            magazineSliderOptions: {
            container: '.slider-magazine',
            mouseDrag: true,
            navigation:false,
            navPosition:'bottom',
            autoplay:true,
            speed:1000,
            autoplayTimeout:3000,
            autoplayHoverPause:true,
            autoplayButton:false,
            loop: true,
            items: 3,
            center:true,
            gutter: 20,
            swipeAngle: 45,
            nav:false,
            controls:false,

            startIndex:0,
            responsive: {
               
                760: {
                items: 5,
                autoplay:false
                }

            },
        },
        }
    }

}
</script>

<style scoped>
  .section-white {
    position:relative;
    z-index: 2;
    border-radius:67px;
    background:#fff;
    margin-top:-50px;
  }
  .section-white .top .slider-container {
    width: 100%;
    padding-top:20px;
    padding-bottom:20px;
    text-align: center;
    font-family: 'pragmatica';
    font-weight: 600;
    font-size:30px;
  }
  .section-white .top {
    display:flex;
    flex-wrap: nowrap;
    justify-content: space-around;
  }
  .section-white .middle {
    display:flex;
    padding-bottom:150px;
  }
  .section-white .middle .left {
    width:60%;
    padding-left:5%;
    padding-right:5%;
  }
  .section-white .middle .right {
    width:40%;
    display:flex;
    align-items:flex-end;
    justify-content: center;
  }
  .section-white .middle .right .content {
    height:80%;
    width:50%;
    background:#000;
  }
  .section-white .middle .left h2 {
    font-size:60px;
    font-family: 'pragmatica';
    font-weight: 500;
  }
</style>