export default {
    data: [
        {
            id: 0,
            name: 'product 001',
            img: '',
            price: 0,
            colors: ["black", "white", "grey"],
        },
        {
            id: 0,
            name: 'product 001',
            img: '',
            price: 0,
            colors: ["black", "white", "grey"],
        },
        {
            id: 0,
            name: 'product 001',
            img: '',
            price: 0,
            colors: ["black", "white", "grey"],
        },
        {
            id: 0,
            name: 'product 001',
            img: '',
            price: 0,
            colors: ["black", "white", "grey"],
        },
    ]
}