export default {
    data: [
        {
            img:'tuto1.jpg',
            link:'ANALYSE MY FACE',
            link_url:'#',
            content:"Find your perfect fit and elevate your style with our face shape recommendations!"
        },
        {
            img:'tuto2.jpg',
            link:'GET MEASURED',
            link_url:'#',
            content:"Correct your vision and your lenses with our accurate pupillary distance measurement."
        },
        {
            img:'tuto2.jpg',
            link:'TRY NOW',
            link_url:'#',
            content:"Try-on each pair of the collection through our photorealistic Virtual Try-On technology. "
        }
    ]
}