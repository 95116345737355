<template>

    <div class="section produce">
      <div class="top">
        <div class="title">*WE<br />PRODUCE</div>
        <div class="columns">
          <div class="column" v-for="(column, index) in columns" :key="index">
            
            <div class="column-title">
              {{ column.title }}
            </div>
            <div class="column-legend">
              <span v-html="column.legend"></span>
             
            </div>
            <img class="column-image" :src="require('../../../../public/assets/home/'+column.img)" />

            <div class="read-more">
              <a href="#">
                READ MORE +
              </a>
            </div>
          </div>
        </div>
        <div class="subtitle">WATCH US MANUFACTURE<br />YOUR GLASSES</div>

      </div>
      <div class="video-section">
        <div class="left">
          <div class="video-left" @click='selectVideo(0)'>

            <img class="video-image" src="../../../../public/assets/home/video.png" />

            <div class="video-description">
              WATCH FULL MOVIE
            </div>
            <div class="video-duration">
             6:20 min
            </div>
          </div>
        </div>
        <div class="or">
          OR
        </div>
        <div class="videos">
          <div class="video" v-for="(video, index) in videos" :key="index" @click='selectVideo(index)'>

            <div class="video-title">
              {{ video.title }}
            </div>
          
            <img class="video-image" :src="require('../../../../public/assets/home/'+video.img)" />

            <div class="video-description">
              {{ video.description }}
            </div>
            <div class="video-duration">
              {{ video.duration }}
            </div>
          </div>
        </div>
      </div>
      <PopupVideo v-if="displayVideoPopup" :hideVideoPopup="hideVideoPopup" :video="videos[selectedVideo]" />
    </div>
</template>

<script>

import produceData from '../../../data/landing/produce_data';
import produceVideos from '../../../data/landing/produce_videos';
import PopupVideo from './PopupVideo.vue';

export default {
    name: "HomeProduceComponent", 
    components: {
      PopupVideo
    },
    data(){
        return{
         columns:produceData.data,
         videos:produceVideos.data,
         selectedVideo:0,
         displayVideoPopup:false
        }
    },
    methods:{
      selectVideo(index) {
        this.selectedVideo=index
        this.displayVideoPopup=true
      },
      hideVideoPopup() {
        this.displayVideoPopup=false
      }
    },
    mounted()
    {
     
    },
}
</script>

<style scoped>
.video-title {
  font-size:25px;
  margin-bottom:10px;
  text-align: center;
}
.video-duration {
  color:#4D4D4D;
  font-size:16px;
}
.video-description {
  font-size:16px;
  margin-top:10px;
  border-bottom:1px solid #000;
}
.video-section .or {
  justify-content: center;
  padding-left:20px;
  padding-right:20px;
  font-size:50px;
  width:10%;
  white-space: nowrap;
  margin-left:5%;
}
.video-section .left {
  width:30%;
  
}

.video-left .video-description,.video-left .video-duration{
  max-width:200px;
  position:relative;
  left:50%;
  transform:translateX(-50%);
}
.video-left {
  padding-left:5%;

}
.video-section .videos .video {
  width:25%;
  
}
.video-section .videos {
  width:65%;
  padding-left:5%;
  padding-right:0%;
  justify-content: space-between;
}
.video-section {
  backdrop-filter: blur(30px);
  border-top:1px dashed black;
  border-bottom:1px dashed black;
  background-color:rgba(255,255,255,0.3);
  padding-top:50px;
  padding-bottom:50px;
  display:flex;
  padding-left:5%;
  padding-right:5%;
  margin-top:50px;
  align-items: center;
}
.videos {
  display:flex;
}
.read-more {
  margin-top:15px;
}
.read-more a {
  color: #000;
  text-decoration:none;
  font-size:16px;
  border-bottom:1px solid #707070;
}
.section.produce .columns {
  display: flex;
  justify-content: space-between;
  margin-top:50px;
}
.column-image {
  margin-top:0;
  max-width:1000%;
  width:100%;
}
.section.produce .column {
  width:30%;
}
.column-legend {
  border-left:1px solid #707070;
  border-right:1px solid #707070;
  padding:10px;
}
.section.produce  .title {
  font-size:100px;
  
}
.section.produce .column-title {
  font-size:50px;
  padding:10px;
  word-break: keep-all;
  min-height:140px;
  border:1px solid #707070;
}
.section.produce  .subtitle {
  font-size:50px;
  margin-top:100px;
}
.section.produce .top {
  padding-left:5%;
  padding-right:5%;
}
.section.produce {

  padding-bottom:100px;
  padding-top:100px;
  background-color:rgba(74,47,47,0.18);
}

</style>