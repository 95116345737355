export default {
    data: [
        {
            title: 'LIGHTWEIGHT',
            img: 'spec2.png',
            description: "and flexible products, weighting 30g in average.",
            
        },
        {
            title: '3D PRINTING',
            img: 'spec5.png',
            description: "is the production method used for each and every product of the Senseye collection.",
            
        },
        {
            title: 'CUSTOMIZABLE',
            img: 'spec3.png',
            description: "without any limit.",
            
        }
        
    ]
}