export default {
    data:[
        {
            img:'col1.jpg',
            title:'SUSTAINABLY',
            legend:'*ZERO WASTE GUARANTEED PROCESS<br />*NO STOCK / EACH PRODUCT IS MADE TO ORDER'
            
        },
        {
            img:'col2.jpg',
            title:'IN FRANCE',
            legend:'*ALL GLASSES ARE PRODUCED DESIGNED, PRODUCED AND ASSEMBLED IN FRANCE, '
        },
        {
            img:'col3.jpg',
            title:'USING 3D PRINTERS',
            legend:'*WE USE 3D PRINTING TO PRODUCE EACH ONE OF OUR GLASSES, ACHIEVING BEST QUALITY '
        }
    ]
}