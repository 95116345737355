export default {
    data:[
        {
            img:'https://api.sens-eye.fr/glasses/61f90585c2561.jpeg',
            img_hover:'https://api.sens-eye.fr/glasses/61f90585c316b.jpeg',
            link:'collaboration/24',
            logo1:'https://api.sens-eye.fr/glasses/6322de83e8979.png',
            logo_product:'https://api.sens-eye.fr/glasses/6332d6ae6cad6.png',
            name:"TÊTE EN L'AIR",
            price:'260'
        },
        {
            img:'https://api.sens-eye.fr/glasses/61f905cc73e4b.jpeg',
            img_hover:'https://api.sens-eye.fr/glasses/61f905cc747df.jpeg',
            link:'collaboration/24',
            logo1:'https://api.sens-eye.fr/glasses/6322de83e8979.png',
            logo_product:'https://api.sens-eye.fr/glasses/6332d6ae6cad6.png',
            name:"TÊTE EN L'AIR",
            price:'260'
        }
    ]
}