<template>
    <div class="section specification">
       <div class="title contenudesk">
        PRODUCT<br />SPECIFICATION
       </div>
       <div class="specification-block">
        <div class="left">
                <div class="specifications">
                    <div v-for="specification in product_specifications" class="specification" :key="specification.img">
                        <div class="spec-name">
                            <img :src="require('../../../../public/assets/home/'+specification.img)" class="spec-image" />
                            {{ specification.title }}</div>
                        <div class="spec-description">{{ specification.description }}</div>
                    </div>
                </div>
            </div>
            <div class="right">
                <img src="../../../../public/assets/home/spec.jpg" />
                <div class="title contenumob">
                    PRODUCT<br />SPECIFICATION
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import productSpecifications from "../../../data/landing/product_specifications"

export default {
    name: "LandingSpecificationComponent",
    props: {
      
    },
    data(){
        return {
            product_specifications:productSpecifications.data
        }
    },
    
    methods: {
    
       
    }
}
</script>

<style scoped>
.title {
    font-size: 70px;
}
.spec-description {
    font-size:14px;
    margin-top:10px;
}
.spec-name {
    text-align: left; 
    font-size:20px;
}
.spec-image {
    width:25px;
    display:block;

    text-align: center;
    margin-bottom:10px;
}
.specifications .specification
{
    border-top:1px solid #808080;
    margin-top:20px;
    padding-top:20px;
    text-align:left;
}
.specifications {
    padding-top:50px;

}
.line {
    width:100%;
    display: block;
    height:1px;
    background:#707070;
}
.section.specification .title img {
    width:40px;
    margin-right: 15px;
}
.section.specification .title {
    font-size:70px;
}
.section.specification {
    padding-left:10%;
    
    position:relative;
    width:100%;
    padding-bottom:150px;
    padding-top:150px;
    background:#fff;
}
.specification-block .left {
    width:40%;
    padding-right:5%;
}
.specification-block .right img {
    width:100%;
    max-width:1000%;
}
.specification-block .right {
    width:60%;
}
.specification-block {
    display:flex;
}
</style>