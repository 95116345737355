<template>
    <div class="section sustainability">
       <div class="top-words" v-if="language=='fr'">
            <div>Zéro Stock</div>
            <div>Zéro Déchet</div>
            <div>IMPRESSION 3D</div>
       </div>
       <div class="top-words" v-else>
            <div>3D Printed</div>
            <div>Zero Stock</div>
            <div>Zero Waste</div>
       </div>

       <div class="sustainability-text" v-if="language=='fr'">
        Nos lunettes sont imprimées en 3D, à la demande, à partir de matériaux bio-sourcés, avec une politique zéro déchet.
       </div>

       <div class="sustainability-text" v-else>
        Our eyewear is 3D printed on demand, using sustainable materials, with a zero waste manufacturing policy.
       </div>


       <div class="images-content">

        <video class="video" muted playsinline autoplay loop >
            <source src="../../../../public/assets/sustainability.mp4" type="video/mp4" />
        </video>
        <!-- <img class="mask" src="../../../../public/assets/images/video_mask.png"  /> -->
        
       </div>
    </div>
</template>

<script>


export default {
    name: "LandingSustainabilityComponent",
    props: ['language'],
    data(){
        return {
         
        }
    },
    
    methods: {
    
       
    },
    mounted() {
        


    }
}
</script>

<style scoped>
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.top-words
{
    text-transform: uppercase;
    font-family: "ProFontWindows";
    font-size:26px;
    width:70%;
    justify-content: space-between;
    display: flex;
    margin-bottom: 20px;
}
.sustainability-text
{
    line-height: 0.8;
    font-size:111px;
}
img.circle {
    animation: rotating 6s linear infinite; 
    display:inline-block;
    position:relative;
    top:2px;
}
img.zero {
    position:relative;
    bottom:0;
    margin-left:50px;
    animation: rotating 6s linear infinite;
    right:0;
    display:inline-block;
    width:200px;
}
.images-content .left {
    width:55%;
    position:absolute;
    left:0;
    top:0;
    height:100%;
    background-position: center top;
  background-image:url(../../../../public/assets/images/sustainabilityleft.jpg);
  background-size:cover;
}
.images-content .right {
    width:43%;
    position:absolute;
    right:0;
    top:0;
    height:75%;
    background-position: center top;
  background-image:url(../../../../public/assets/images/sustainabilityright.jpg);
  background-size:cover;
}
.images-content .mask {
    width:101%;
    height:101%;
    position:absolute;
    left:3px;
    top:0;
    z-index: 2;
}
.images-content .video
{
    width:100%;
    position: relative;
    z-index: 1;
    border-radius: 10px;
}
.images-content {
    position:relative;
    width:100%;
    height:auto;
    margin-top:100px;
}

.sustainability-text .small 
{
    margin-top: 20px;
    font-family: "ProFontWindows";
    white-space: nowrap;
    font-size:23px;
    color:#707070;
   display:block;
}
.sustainability-text.contenudesk
{
    text-transform: uppercase;
    line-height:1.4;
    font-size:55px;
    display:inline-block;
    width:calc(100% - 250px);
}
.rounded-text-light {

    display:inline-block;
    line-height: 1;
white-space: nowrap;
    border-radius:34px;
    padding:0px 10px 5px 10px;
    border:1px solid black;
}
.rounded-text-dark {

    display:inline-block;
    line-height: 1;
    border-radius:34px;
    color:#fff;
    white-space: nowrap;
    padding:0px 10px 5px 10px;
    background:#000;
    border:1px solid black;
}
.section.sustainability {
    position:relative;
    width:100%;
    padding-left:10%;
    padding-right:10%;
    padding-bottom:120px;
    padding-top:180px;
    background:#fff;
}
@media screen and (max-width:1024px)
{
    
    .top-words
    {
        font-size:18px;
    }
.images-content .mask
    {
        display: none;
    }

    .sustainability-text
    {
        font-size:55px;
    }

}

@media screen and (max-width:760px)
{
    img.zero {
        position:absolute;
        z-index:3;
        bottom:30px;
        animation: rotating 6s linear infinite;
        right:0;
        width:100px;
}

    .rounded-text-light,.rounded-text-dark
    {
        padding:0px 10px 3px 10px;
    }
    .images-content .video
{
   
    position: absolute;
    width:100%;
    height:auto;
    z-index: 0;
}
    .images-content .mask
    {
        display: none;
    }
    .sustainability-text .small {
        white-space: normal !important;
        font-size:14px !important;
        color:#000 !important;
        width:50%;
        padding-right:60px;
        line-height: 1;
    }
    .section.sustainability
    {
        padding-top:100px !important;
        padding-bottom:30px !important;
        padding-left:5% !important;
        padding-right:5% !important;
    }
    img.circle {
        top:1px !important;
        width:18px !important;
    }
    .sustainability-text {
        font-size:38px !important;
        line-height: 0.8 !important;
    }
    .images-content .right {
        display: none;
    }
    .images-content .left {
        width:100%;
    }
    .images-content {
        margin-top:20px !important;
        height:32vh !important;
    }
    .top-words
    {
        width:100% !important;
        font-size:12px !important;
    }
}
</style>