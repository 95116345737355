<template>
  <div class="section tutorial landing">
      <div class="line-tuto line1"></div>
      <div class="content">
        
        <div class="title">
         <span class="outline">UNPARALLELED</span> SHOPPING<br />EXPERIENCE, ONLINE. 
        </div>

        <div class="slider">
            <div class="slider-tuto real">
              <template v-for="(slide,index) in slides" :key="index">
                <div class="carousel-cell" :class="{first:index==0}">
                  <div class="img" :style="'background-image:url('+require('../../../../public/assets/home/'+slide.img) +');'" />
                  <div class="slide-description">
                    {{ slide.content }}
                  </div>
                  <a :href="slide.link_url" class="slide-link">
                    {{ slide.link }}
                  </a>
                </div>
              </template>
            </div>
            <div id="prev-tuto"><img src="../../../../public/assets/images/arrow_scan.svg" width="45" /></div>
            <div id="next-tuto"><img src="../../../../public/assets/images/arrow_scan.svg" width="45" /></div>
        </div>
       
      </div>
    
    </div>
</template>

<script>

import { tns } from "../../../../node_modules/tiny-slider/src/tiny-slider"

import tutoData from "../../../data/landing/tutoDesk_data";


export default {
    name: "HomeTutoDesk",
    props: {
        currentTuto: Number,
    },
    data(){
      
        return{
            slides:tutoData.data,
            tutoSliderOptions: {
                container: '.slider-tuto',
                mouseDrag: true,
                navigation:false,
                navPosition:'bottom',
                speed:1000,
                items: 2,
                loop:false,
                gutter: 20,
                center:false,
                swipeAngle: 45,
                nav:false,
                controls:true,
                prevButton:'#prev-tuto',  // Node reference
                nextButton: '#next-tuto',
                startIndex:0,
                responsive: {
                  760: {
                      items: 2.8
                  }
                },
            },
            slider_tuto:null,
        }
    },
    mounted()
    {
      if(window.innerWidth>760) {

     
        this.slider_tuto = tns(
              this.tutoSliderOptions
          );

      }
      window.addEventListener('resize', this.handleResize);
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
      handleResize() {
          if(window.innerWidth>760) {
            if(this.slider_tuto==null) {
              this.slider_tuto = tns(
                this.tutoSliderOptions
            );
            }
           
          }
          else {
      
            if(this.slider_tuto!=null) {
              this.slider_tuto.destroy()
              this.slider_tuto=null
            }
            
          
          }
      },
    }
}
</script>

<style>
.section.tutorial.landing .slider-tuto .carousel-cell:last-child
{
  margin-right:-50vw;
}
.section.tutorial.landing #prev-tuto[aria-disabled=true] {
  opacity:0;
}.section.tutorial.landing #next-tuto[aria-disabled=true] {
  opacity:0;
}

.section.tutorial.landing #next-tuto {
  right: 20px !important;
}
.carousel-cell.first 
{
  width:50vw !important;
}
.slider-tuto .slide-link {
  margin-top:15px;
  font-size:14px;
  display: block;
  border:1px solid #707070;
  font-weight: 500;
  padding-top:15px;
  padding-bottom:15px;
  width:100%;
  text-align:center;
  text-decoration:none;
  background:rgba(255,255,255,0.1);
  backdrop-filter:blur(30px);
  border-radius:8px;
}
.slide-description {
  margin-top:10px;
  font-size:16px;
  min-height:38px;
}
.slide-description,.slide-link {
  color:#fff;
 
}

.section.tutorial .title {
    font-size: 70px;
    color: #fff;
    text-decoration: none;
    text-align: left;
    padding-left:5%;
    width:100%;
    padding-top:50px;
}

.section.tutorial .title .outline {

    transition: all 0.5s;
   
    background-position: 0 0;
  
    background: -webkit-linear-gradient(45deg, #CCDCF8 5%, #DCD6F7 95%);
  -webkit-background-clip: text;
    text-stroke: 2px transparent;
    -webkit-text-stroke: 2px transparent;
    white-space: nowrap;
    color: #000;
    text-decoration: none;
}
.section.tutorial {
  padding-top:100px;
  padding-bottom:100px;
  position:relative;
}


.section.tutorial .content {
  display:block;
  border-top:1px solid #707070;
  border-bottom:1px solid #707070;
}
.section.tutorial .content .left {
  padding-top:50px;
  padding-bottom:50px;
  display:flex;
  justify-content: center;
  align-items: center; 
}

  .section.tutorial {
    background:#000;
    margin-top:0px;
    position:relative;
    z-index: 1;
  }

  .line-tuto {
  background:#707070;
  position:absolute;
  height:100%;
  width:1px;
  top:0;
}




.tns-controls{
  display: none !important;
}

.slider-tuto.real .carousel-cell .img
{
  display:inline-block;
    width:100%;
    height: 28vw;
    border-radius: 12px;
    background-size:cover;
    background-position:center center;
}
.slider,
.slider-tuto
{
    position: relative;

}

.slider {
  width:95%;
  margin-left:5%;
  padding-top:50px;
  padding-bottom:100px;
}

.slider-tuto{
  display: flex;
}

#prev-tuto img
{
    transform:rotate(-180deg) ;
}
#prev-tuto
{
    position:absolute;
    top:50%;
    left:-60px !important;
    z-index:3;
    transform: translateY(-50%);
}
#next-tuto
{
    position:absolute;
    top:50%;
    right:-60px !important;
    transform: translateY(-50%);
    z-index:3;
}

#next-tuto img
{
    transform:rotate(0deg) !important;
}


.line-tuto.line1
{
  left:50%;
}

.line-tuto.line2
{
  left:30%;
}

.line-tuto.line3
{
  left:50%;
}
.line-tuto.line4
{
  left:70%;
}
.line-tuto.line5
{
  left:90%;
}

.marqueetext {
  white-space: nowrap;
  animation: linear marqueelike 20s infinite;
}

.marqueetext p {
  display: inline;
  line-height: 100%
}

.marquee-content {
	display:flex;
	overflow: hidden;
  }

</style>