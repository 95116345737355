<template>
      <div class="creations-content">
        <div class="title contenudesk">#EXPRESSYOURINDIVIDUALITY</div>
        <div class="title contenumob">#EXPRESSYOUR<br />INDIVIDUALITY</div>
        <br /><br />
        <div class="note">
          <img v-if="average>=1" src="../../../../public/assets/images/star.svg" width="21" />
          <img v-else src="../../../../public/assets/images/star-empty.svg" width="21" />

          <img v-if="average>=2" src="../../../../public/assets/images/star.svg" width="21" />
          <img v-else src="../../../../public/assets/images/star-empty.svg" width="21" />

          <img v-if="average>=3" src="../../../../public/assets/images/star.svg" width="21" />
          <img v-else src="../../../../public/assets/images/star-empty.svg" width="21" />

          <img v-if="average>=4" src="../../../../public/assets/images/star.svg" width="21" />
          <img v-else src="../../../../public/assets/images/star-empty.svg" width="21" />

          <img v-if="average>=5" src="../../../../public/assets/images/star.svg" width="21" />
          <img v-else src="../../../../public/assets/images/star-empty.svg" width="21" />

          <span class="rate">{{average.toFixed(1)}} / 5</span>
        </div>
        <div class="count">2566 personnes <span class="underline">nous recommandent</span></div>
        <div class="slider">
            <div class="slider-creations real">
              <template v-for="(creation_slide,index) in loop_creations" :key="index">
                <div class="carousel-cell">
                  <img :src="require('../../../../public/assets/images/'+creation_slide.img)" /><br />
                  <div class="title-slide">{{ creation_slide.title }} <img class="custom-img" src="../../../../public/assets/home/custom.png" /> CUSTOM</div>
                  <div class="name">{{ creation_slide.name }}</div>
                </div>
              </template>
            </div>
            <div id="prev-creation"><img src="../../../../public/assets/images/arrow_scan.svg" width="45" /></div>
            <div id="next-creation"><img src="../../../../public/assets/images/arrow_scan.svg" width="45" /></div>
        </div>

    <a class="send-link">*SEND US YOUR CREATION BY <span class="underline">CLICKING HERE</span></a>
    </div>
</template>

<script>

import { tns } from "../../../../node_modules/tiny-slider/src/tiny-slider"
import creationData from '../../../data/landing/creation_data';

export default {
    name: "HomeCreation",
    props: {
        average: Number
    },
    data(){
        return{
            creationSliderOptions: {
                container: '.slider-creations',
                mouseDrag: true,
                navigation:true,
                navPosition:'bottom',
                autoplay:true,
                speed:1000,
                autoplayTimeout:3000,
                autoplayHoverPause:false,
                autoplayButton:false,
                loop: true,
                items: 2,
                center:true,
                gutter: 20,
                swipeAngle: 45,
                nav:false,
                controls:true,
                prevButton:'#prev-creation',  // Node reference
                nextButton: '#next-creation',
                startIndex:0,
                responsive: {
                  760: {
                        items: 4
                        }
                },
        },
        slider_creation:null,
        loop_creations:creationData.data,
        }
    },
    mounted()
    {
      this.slider_creation = tns(
            this.creationSliderOptions
        );
    }

}
</script>

<style scoped>
.slider-creations .custom-img
{
  display: inline-block;
  width:10px !important;
  margin-left:3px;
  margin-right:3px;
}
.slider-creations .name {
  font-size:13px;
  color:#646464;
  display:block;
}
.slider-creations .title-slide {
  font-size:16px;
  display:inline-block;
  border-bottom:1px solid #646464;
}
.count .underline {
  border-bottom:1px solid black;
}
.count {
  font-size:18px;
  display:inline-block;
  padding-left:15px;
  padding-top:15px;
}

a.send-link .underline {
  border-bottom: 1px solid #000;
}
a.send-link {
  text-decoration: none;
  color:#000;

  font-size:22px;
  position: relative;
  left:5%;
}
.creations-content {
  padding-bottom:150px;
}
.rating img,
.note img
{
  display: inline-block;
  vertical-align: top;
}

.note
{
  border-radius: 11px;
  padding:15px 20px;
  margin-left:5%;
  display: inline-block;
  vertical-align: top;
  
  background: transparent linear-gradient(90deg, rgba(203, 220, 248, 1) 0%, rgba(221, 213, 247, 1) 100%) 0% 0% no-repeat padding-box;
  border: 1px solid rgba(255, 255, 255, 0);
}
.rate
{
  font-size: 18px;
  margin-left: 5px;
}

.slider-creations .tns-controls,.slider-creations .tns-nav {
  display:none !important;
}


.slider-creations.real .carousel-cell img
{
  display:inline-block;
    width:100%;
    height:auto;
    border-radius: 13px;
}
.slider,
.slider-creations
{
    position: relative;

}

.slider-creations{
  display: flex;
 
}
.slider {
  width:100%;
  margin-left:0;
}
#prev-creation img
{
    transform:rotate(-180deg) ;
}
#prev-creation
{
    position:absolute;
    top:50%;
    left:10px;
    z-index:3;
    transform: translateY(-50%);
}
#next-creation
{
    position:absolute;
    top:50%;
    right:10px;
    transform: translateY(-50%);
    z-index:3;
}

.creations-content .title {
    text-transform: uppercase;
    white-space:normal;
    line-height:1;
  }

  .slider-creations.real .carousel-cell img
{
    width:100%;
    height:auto;
    border-radius: 13px;
}
.creations-content .title
{
    position: relative;
    width:90%;
    margin-left:auto;
    margin-right:auto;
    padding:0;
    font-size:70px;
    background-repeat: no-repeat;
    -webkit-background-clip: text;
    transition: all 0.5s;
    background-image: -webkit-linear-gradient(#000 50%, #000 50%);
    background-position: 0 0;
    background-size: 0% 0%;
    text-stroke: 1px #000;
    -webkit-text-stroke: 1px #000;
    color: #000;
    white-space: nowrap;
    color: transparent;
    text-decoration: none;
}

#prev-creation img
{
    transform:rotate(-180deg) ;
}
.slider button 
{
    display:none !important;
}
.slider,
.slider-creations
{
    position: relative;

}
.slider button 
{
    display:none !important;
}

.tns-controls{
  display: none !important;
}


</style>